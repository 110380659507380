// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about_us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact_page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-help-us-js": () => import("./../../../src/templates/help_us.js" /* webpackChunkName: "component---src-templates-help-us-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-news-item-js": () => import("./../../../src/templates/news_item.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-study-hall-js": () => import("./../../../src/templates/study_hall.js" /* webpackChunkName: "component---src-templates-study-hall-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

