module.exports = [{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"bhimraohu","defaultLang":"hu","langs":["hu","en-us"],"pages":[{"type":"homepage","match":"/:lang?","path":"/:lang?","component":"/opt/build/repo/src/templates/homepage.js","langs":["hu","en-us"]},{"type":"about_us","match":"/:lang?/:uid","path":"/:lang?/:uid","component":"/opt/build/repo/src/templates/about_us.js","langs":["hu","en-us"]},{"type":"news","match":"/:lang?/:uid","path":"/:lang?/:uid","component":"/opt/build/repo/src/templates/news.js","langs":["hu","en-us"]},{"type":"projects","match":"/:lang?/:uid","path":"/:lang?/:uid","component":"/opt/build/repo/src/templates/projects.js","langs":["hu","en-us"]},{"type":"contact_page","match":"/:lang?/:uid","path":"/:lang?/:uid","component":"/opt/build/repo/src/templates/contact_page.js","langs":["hu","en-us"]},{"type":"help_us","match":"/:lang?/:uid","path":"/:lang?/:uid","component":"/opt/build/repo/src/templates/help_us.js","langs":["hu","en-us"]},{"type":"team","match":"/:lang?/:uid","path":"/:lang?/:uid","component":"/opt/build/repo/src/templates/team.js","langs":["hu","en-us"]},{"type":"search","match":"/:lang?/:uid","path":"/:lang?/:uid","component":"/opt/build/repo/src/templates/search.js","langs":["hu","en-us"]},{"type":"project","match":"/:lang?/projekt/:uid","path":"/:lang?/projekt/:uid","component":"/opt/build/repo/src/templates/project.js","langs":["hu","en-us"]},{"type":"study_hall","match":"/:lang?/projekt/tanoda/:uid","path":"/:lang?/projekt/tanoda/:uid","component":"/opt/build/repo/src/templates/study_hall.js","langs":["hu","en-us"]},{"type":"news_item","match":"/:lang?/hir/:uid","path":"/:lang?/hir/:uid","component":"/opt/build/repo/src/templates/news_item.js","langs":["hu","en-us"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"static/images/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f039fb7f118ac1f36a61a8e21e8b61b0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
